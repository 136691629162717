<template>
    <div style="background-color: white;">
        <header>
            <v-container>
                <v-row align="center" class="about-row">
                    <v-col cols="12" sm="6" class="py-5">
                        <p class="display-1 font-weight-bold secondary--text mt-5 d-xl-none">ABOUT {{siteName}}</p>
                        <p class="textColor--text text-justify d-xl-none">{{description}}</p>
                        <p class="display-3 font-weight-bold secondary--text mt-5 d-none d-xl-flex">ABOUT {{siteName}}</p>
                        <p class="text-h5 textColor--text text-justify d-none d-xl-flex">{{description}}</p>
                    </v-col>
                    <v-col cols="12" sm="6" class="d-none d-sm-block">
                        <v-img class="mx-auto" src="../assets/logo-big.png" max-width="500px"></v-img>
                    </v-col>
                </v-row>
            </v-container>
            <div class="arrow slide-bottom">
                <v-btn icon x-large @click="$vuetify.goTo('.service-section')"><v-icon x-large color="secondary">mdi-arrow-down</v-icon></v-btn>
            </div>
            <div class="wave"><img src="../static/icons/wave.svg"></div>
        </header>
        <v-container class="service-section">
            <v-row>
                <v-col cols="12" sm="6" md="4">
                    <v-card min-height="200" class="text-center py-5">
                        <img src="../static/icons/conveyor.png">
                        <v-card-title><span class="mx-auto primary--text">Experience</span></v-card-title>
                        <v-card-text class="textColor--text">
                            30 years of experience in the Travel Industry
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-card min-height="200" class="text-center py-5">
                        <img src="../static/icons/info.png">
                        <v-card-title><span class="mx-auto primary--text">Customer Care</span></v-card-title>
                        <v-card-text class="textColor--text">
                            Large experienced staff experienced in customer care for the travel sector
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-card min-height="200" class="text-center py-5">
                        <img src="../static/icons/money.png">
                        <v-card-title><span class="mx-auto primary--text">Prices</span></v-card-title>
                        <v-card-text class="textColor--text">
                            Most competitive prices in the industry
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-card min-height="200" class="text-center py-5">
                        <img src="../static/icons/pro.png">
                        <v-card-title><span class="mx-auto primary--text">Promotions</span></v-card-title>
                        <v-card-text class="textColor--text">
                            Promotional fares offered daily
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-card min-height="200" class="text-center py-5">
                        <img src="../static/icons/clock.png">
                        <v-card-title><span class="mx-auto primary--text">Quick&Easy</span></v-card-title>
                        <v-card-text class="textColor--text">
                            Get cheap flight tickets and hotels rooms in a simple few short clicks!
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-card min-height="200" class="text-center py-5">
                        <img src="../static/icons/discount.png">
                        <v-card-title><span class="mx-auto primary--text">Offers</span></v-card-title>
                        <v-card-text class="textColor--text">
                            Special offers to our loyal clients
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
  title: '-About Us',
  computed: {
    siteName () {
      return process.env.VUE_APP_DEFAULT_TITLE
    },
    description () {
      return process.env.VUE_APP_DEFAULT_DESC
    }
  },
  created () {
    window.scrollTo(0, 0)
  }
}
</script>

<style>
    #about {
        line-height: 35px !important;
    }
    header {
        width: 100%;
        height: 100vh;
        position: relative;
        background-color: #f8f9fb;
    }
    .wave {
        position: absolute;
        bottom: -10px;
        width: 100%;
    }
    .wave img {
        width: 100%;
    }
    .about-row {
        height: 74vh;
    }
    #logo {
        height: 65vh;
        margin: 0 auto;
    }
    .arrow {
        position: absolute;
        top: 78%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 999;
    }
    .slide-bottom {
        -webkit-animation: slide-bottom 1s infinite alternate-reverse both;
                animation: slide-bottom 1s infinite alternate-reverse both;
    }
    @-webkit-keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(20px);
                transform: translateY(20px);
    }
    }
    @keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(20px);
                transform: translateY(20px);
    }
    }
</style>
